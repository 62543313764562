import { observer } from "mobx-react-lite";

import { Col, Row, Table, Typography } from "antd";
import {
  getDefaultColumnProps,
  IColumns,
} from "../../../app/common/table/utils";
import { useEffect, useState } from "react";
import { toJS } from "mobx";
import { useStore } from "../../../app/stores/store";

const { Text, Title } = Typography;
type Information = {
  parameterHistory: ParameterHistoryInfo;
};
type ParameterHistoryInfo = {
  paciente: string;
  solicitud: string;
  claveParametro: string;
  nombreParametro: string;
  solicitudId: string;
  parametroId: string;
  estudioId: number;
  valorInicial: string;
  valorFinal: string;
};

const ClinicalResultsHistory = ({ parameterHistory }: Information) => {
  const { clinicResultsStore } = useStore();
  const { getHistoryResults } = clinicResultsStore;
  const [history, setHistory] = useState<any[]>([]);
  const columns: IColumns<any> = [
    {
      ...getDefaultColumnProps("resultado", "Resultado", {
        width: "15%",
      }),
      render: (text, record) => {
        let fieldRange =
          parseFloat(parameterHistory.valorInicial) > parseFloat(text ?? 0) ||
          parseFloat(text ?? 0) > parseFloat(parameterHistory.valorFinal);
        return (
          <Text style={{ color: fieldRange ? "red" : "black" }}>{text}</Text>
        );
      },
    },
    {
      ...getDefaultColumnProps("unidades", "Unidades", {
        width: "15%",
      }),
    },
    {
      ...getDefaultColumnProps("usuario", "Usuario", {
        width: "15",
      }),
    },
    {
      ...getDefaultColumnProps("fecha", "Fecha y Hora", {
        width: "15",
      }),
    },
  ];

  useEffect(() => {
    console.log("parameterHistory", toJS(parameterHistory));
    const getHistory = async () => {
      const historyResult = await getHistoryResults(
        parameterHistory.solicitudId,
        parameterHistory.estudioId,
        parameterHistory.parametroId
      );

      setHistory(historyResult);
    };
    getHistory();
  }, []);

  return (
    <>
      <Row>
        <Col span={3}>
          <Text strong>Paciente:</Text>
        </Col>
        <Col>
          <Text>{parameterHistory.paciente}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={3}>
          <Text strong>Solicitud:</Text>
        </Col>
        <Col>
          <Text>{parameterHistory.solicitud}</Text>
        </Col>
      </Row>
      <Row style={{ paddingTop: 10 }}>
        <Col span={6}>
          <Text strong>Nombre parámetro:</Text>
        </Col>
        <Col>
          <Text>
            {parameterHistory.nombreParametro} (
            {parameterHistory.claveParametro})
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table columns={columns} dataSource={history}></Table>
        </Col>
      </Row>
    </>
  );
};

export default observer(ClinicalResultsHistory);
